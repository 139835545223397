<template>
  <section>
    <fetch-loading v-if="showLoading" label="You are being redirected..." />
    <fetch-error v-else-if="loadingState.error" :error="loadingState.error" />
    <div v-else class="container">
      <div class="log-item">
        <span>showLoading</span>
        <pre>{{ showLoading }}</pre>
      </div>
      <div class="log-item">
        <span>fullPath</span>
        <pre>{{ route.fullPath }}</pre>
      </div>
      <div class="log-item">
        <span>from</span>
        <pre>{{ route.query?.from }}</pre>
      </div>
      <div class="log-item">
        <span>debug</span>
        <pre>{{ route.query?.debug }}</pre>
      </div>
      <div class="log-item">
        <span>redirectUrl</span>
        <pre>{{ redirectUrl }}</pre>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import useRedirect from '~/composables/useRedirect'

const nuxtApp = useNuxtApp()
const { getRedirectUrl } = await nuxtApp.runWithContext(() => useRedirect())

const route = useRoute()
const loadingState = ref<{ loading: boolean; error: boolean | unknown }>({
  loading: true,
  error: false
})

const showLoading = computed(() => {
  if (loadingState.value.error) {
    return false
  } else if (route.query?.debug) {
    return loadingState.value.loading
  } else {
    return true
  }
})

const target = getTarget()
const { data: redirectUrl, error } = await useAsyncData(() =>
  getRedirectUrl(target)
)

if (redirectUrl.value && !route.query?.debug) {
  await navigateTo(redirectUrl.value, { redirectCode: 301 })
} else if (error.value) {
  loadingState.value.error = error.value
  throw error.value
}
loadingState.value.loading = false

function getTarget() {
  const target = route.query?.from || route.fullPath
  if (Array.isArray(target)) {
    return target[0] as string
  } else {
    return target
  }
}
</script>
<style scoped lang="postcss">
.log-item {
  @apply flex flex-row space-x-2 py-2;
}

.log-item > span {
  @apply w-1/12;
}
</style>
